// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------
/// font family
$text-family: 'Assistant', sans-serif !default; //fond sizes
$font-size-base: 17px !default;
$font-size-small: ceil(($font-size-base * 0.94)) !default; // ~16px
$font-size-medium: floor(($font-size-base * 1.18)) !default; // ~20px
$font-size-large: ceil(($font-size-base * 1.3)) !default; // ~22px
$font-weight-base: 300;
//@type general
$max-width: 1230px !default;
$padding-base: 15px;
$section-spacer-height: 20px;
$row-spacer-height: 20px;

/// Breakpoints map
$breakpoints: (
  'sbsiteSizeMobile': (max-width: 992px),
  'mobile-m': (max-width: 375px),
  'mobile-l': (max-width: 425px),
  'extra-small': (max-width: 480px),
  'small': (max-width: 768px),
  'medium': (max-width: 992px),
	'1100': (max-width: 1100px),
  'large': (max-width: 1200px),
  'larger': (max-width: 1300px),
  'huge': (max-width: 1400px),
	'1500': (max-width: 1500px),
) !default;

//dir set
@if $dir != ltr and $dir != rtl {
  $dir: ltr;
}

//left / right according to dir
$left: if-ltr(right, left);
$right: if-ltr(left, right);

//Hamburger color
$hamburger-layer-color: #000;

//Header phone color
$header-phone-color: #000;

//Loaders phone color
$loaders-primary-color: #000;

// Main colors

$accent: #00acee;
$accent-blue:#0081b2;
$accent-green: #88c334;

//Social color schemes
$clr-facebook: #3b5998;
$clr-youtube: #FF0000;
$clr-twitter: #1da1f2;
$clr-instagram: #c32aa3;
$clr-linkedin: #007bb5;
$clr-googleplus: #db4437;
$clr-whatsapp: #25d366;

